import React from 'react';
import PropTypes from 'prop-types';
import { Card, Tip } from '@lxlabs/change-ad-components';
import styled from 'styled-components';
import Graphic from './graphic';

const Style = styled(Card)`
  margin: 0px !important;
`;

const TipCard = ({
  graphic, children, backgroundColor, padding,
}) => (
  <Style
    padding={{
      top: 'medium',
      bottom: 'medium',
      left: 'medium',
      right: 'small',
      ...padding,
    }}
    backgroundColor={backgroundColor}
  >
    <Tip leftContent={<Graphic name={graphic} size="medium" />} italic={false} gap="medium">
      {children}
    </Tip>
  </Style>
);

TipCard.propTypes = {
  backgroundColor: PropTypes.string,
  padding: PropTypes.shape({
    top: PropTypes.string,
    bottom: PropTypes.string,
    left: PropTypes.string,
    right: PropTypes.string,
  }),
  graphic: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

TipCard.defaultProps = {
  backgroundColor: 'primary',
  padding: {},
};

export default TipCard;
